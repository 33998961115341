<script lang="ts">
	import { assets } from '$app/paths';
	// @ts-ignore
	import { scrollto } from 'svelte-scrollto';
	import auth from '$stores/authStore';
	import Dialog from '@smui/dialog';

	let mealsScreenshotDialogIsOpen = false;
	let plansScreenshotDialogIsOpen = false;
</script>

<!-- Hero unit -->
<div class="hero">
	<div class="hero__overlay hero__overlay--gradient" />
	<div class="hero__mask" />
	<div class="hero__inner">
		<div class="container">
			<div class="hero__content">
				<div class="hero__content__inner" id="navConverter">
					<h1 class="hero__title">
						The simplest, most straightforward way to plan your family's weekly meals
					</h1>
					<p class="hero__text">
						Simple Weekly Meal Planner is a <u>free</u>, easy-to-use tool which lets you map out
						which meals you want to eat each week and ensure you have all the ingredients you need.
					</p>
					<a href="#features" use:scrollto={'#features_section'} class="button hero__button"
						>Learn more</a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="hero__sub" use:scrollto={'#features_section'}>
	<span id="scrollToNext" class="scroll">
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			class="hero__sub__down"
			fill="currentColor"
			width="512px"
			height="512px"
			viewBox="0 0 512 512"
			style="enable-background:new 0 0 512 512;"
			xml:space="preserve"
			><path
				d="M256,298.3L256,298.3L256,298.3l174.2-167.2c4.3-4.2,11.4-4.1,15.8,0.2l30.6,29.9c4.4,4.3,4.5,11.3,0.2,15.5L264.1,380.9c-2.2,2.2-5.2,3.2-8.1,3c-3,0.1-5.9-0.9-8.1-3L35.2,176.7c-4.3-4.2-4.2-11.2,0.2-15.5L66,131.3c4.4-4.3,11.5-4.4,15.8-0.2L256,298.3z" /></svg>
	</span>
</div>
<!-- Steps -->
<!-- svelte-ignore a11y-missing-content -->
<a name="features" id="features_section" />
<div class="steps landing__section">
	<div class="container">
		<h2>How it works</h2>
	</div>
	<div class="container">
		<div class="steps__inner">
			<div class="step">
				<div class="step__media">
					<img src="{assets}/images/undraw_cooking_lyxy.svg" alt="cooking" class="step__image" />
				</div>
				<h4>Manage your meals</h4>
				<p class="step__text">
					Add all the meals you like to make and stop agonizing over what to make each day. The app
					remembers your meal preferences and makes it easy to re-use meals.
				</p>
			</div>
			<div class="step">
				<div class="step__media">
					<img
						src="{assets}/images/undraw_date_picker_re_r0p8.svg"
						alt="calendar"
						class="step__image" />
				</div>
				<h4>Plan your week</h4>
				<p class="step__text">
					Each week, create a plan for which meals you'll make each day. The app will generate an
					easy-to-use shopping list for you based on the needed ingredients.
				</p>
			</div>
			<div class="step">
				<div class="step__media">
					<img
						src="{assets}/images/undraw_people_re_8spw.svg"
						alt="eating together"
						class="step__image" />
				</div>
				<h4>Collaborate</h4>
				<p class="step__text">
					Share your meal planning with a partner or the whole family to get others involved in the
					planning. Track who is cooking and when we need to eat for each day.
				</p>
			</div>
		</div>
	</div>
</div>

<!-- Screenshots-->
<!-- svelte-ignore a11y-missing-content -->
<a name="screenshots" id="screenshots_section" />
<div class="steps landing__section">
	<div class="container">
		<h2>Screenshots</h2>
	</div>
	<div class="container" style="max-width: 100%">
		<div class="steps__inner" style="gap:16px;">
			<div class="step">
				<h4 id="add-your-meals">Add your Meals</h4>
				<p class="step__text" id="add-your-meals-content" style="max-width:80%;text-align:center;">
					Add all your meals in an easy-to-use UI.
				</p>
				<div class="step__media">
					<img
						src="{assets}/images/screenshot-meals.png"
						alt="Meals"
						role="button"
						tabindex="0"
						title="Click to enlarge"
						class="clickable-image"
						on:keydown={(e) => {
							mealsScreenshotDialogIsOpen = e?.key === 'Enter' && !mealsScreenshotDialogIsOpen;
						}}
						on:click={(e) => {
							mealsScreenshotDialogIsOpen = !mealsScreenshotDialogIsOpen;
						}} />
				</div>
			</div>
			<div class="step">
				<h4 id="plan-your-meals">Plan your Week</h4>
				<p
					class="step__text"
					id="plan-your-meals-content"
					style="max-width:80%; text-align:center;">
					Select meals and add notes for each day of the week.
				</p>
				<div class="step__media">
					<img
						src="{assets}/images/screenshot-plans.png"
						alt="Plans"
						role="button"
						tabindex="0"
						title="Click to enlarge"
						class="clickable-image"
						on:keydown={(e) => {
							plansScreenshotDialogIsOpen = e?.key === 'Enter' && !plansScreenshotDialogIsOpen;
						}}
						on:click={(e) => {
							plansScreenshotDialogIsOpen = !plansScreenshotDialogIsOpen;
						}} />
				</div>
			</div>
		</div>
	</div>
</div>

<Dialog
	bind:open={mealsScreenshotDialogIsOpen}
	aria-labeledby="add-your-meals"
	aria-describedby="add-your-meals-content"
	surface$style="max-width: calc(100vw - 10%);">
	<img
		src="{assets}/images/screenshot-meals.png"
		alt="Meals"
		role="button"
		tabindex="0"
		class="clickable-image"
		on:keydown={(e) => {
			mealsScreenshotDialogIsOpen = !mealsScreenshotDialogIsOpen;
		}}
		on:click={(e) => {
			mealsScreenshotDialogIsOpen = !mealsScreenshotDialogIsOpen;
		}} />
</Dialog>

<Dialog
	bind:open={plansScreenshotDialogIsOpen}
	aria-labeledby="plan-your-meals"
	aria-describedby="plan-your-meals-content"
	surface$style="max-width: calc(100vw - 10%);">
	<img
		src="{assets}/images/screenshot-plans.png"
		alt="Meals"
		role="button"
		tabindex="0"
		class="clickable-image"
		on:keydown={(e) => {
			plansScreenshotDialogIsOpen = !plansScreenshotDialogIsOpen;
		}}
		on:click={(e) => {
			plansScreenshotDialogIsOpen = !plansScreenshotDialogIsOpen;
		}} />
</Dialog>

<!-- Last row -->
<div class="container">
	<div class="expanded__inner">
		<div class="expanded__media">
			<img
				src="{assets}/images/undraw_eating_together_re_ux62.svg"
				alt="eating together"
				class="expanded__image" />
		</div>
		<div class="expanded__content">
			<h2 class="expanded__title">Take the anxiety out of deciding what to eat each week</h2>
			<p class="expanded__text">
				After a one-time meal setup in Simple Weekly Meal Planner, you can map out your meal plan
				each week in just seconds and start your week feeling prepared.
			</p>
		</div>
	</div>
</div>
<!--
<div class="expanded landing__section">
	<div class="container">
		<div class="expanded__inner">
			<div class="expanded__media">
				<img src="{assets}/images/undraw_frameworks.svg" class="expanded__image" />
			</div>
			<div class="expanded__content">
				<h2 class="expanded__title">Framework agnostic. Your front-end setup, your choice.</h2>
				<p class="expanded__text">
					Evie has zero dependencies and uses vanilla JavaScript for a few functions with minimal
					footprint. You can use React, Vue, Angular, jQuery or whatever you prefer.
				</p>
			</div>
		</div>
	</div>
</div>
<div class="expanded landing__section">
	<div class="container">
		<div class="expanded__inner">
			<div class="expanded__media">
				<img src="{assets}/images/together.svg" class="expanded__image" />
			</div>
			<div class="expanded__content">
				<h2 class="expanded__title">Ready for production or rapid prototyping</h2>
				<p class="expanded__text">
					Landing, authentication and a few other pages to select from, despite the small size.
					Tested on production at unDraw with amazing speeds and unopinionated on how to structure
					your project. We really hope you'll find it awesome and useful!
				</p>
			</div>
		</div>
	</div>
</div>
-->
<!-- Call To Action -->
<div class="cta cta--reverse">
	<div class="container">
		<div class="cta__inner">
			<h2 class="cta__title">Get started now, it's free!</h2>
			<div>
				{#if $auth.isLoggedIn}
					<a href="/app" class="button button__accent">Go to the app</a>
				{:else}
					<a href="/signup" class="button button__accent">Sign up</a>
					&nbsp;or&nbsp;
					<a href="/login" class="button button__secondary">Log in</a>
				{/if}
			</div>
			<a href="https://play.google.com/store/apps/details?id=com.fonner.simpleweeklymealplanner">
				<img src="{assets}/images/google-play.png" alt="" style="width: 200px" />
			</a>
		</div>
	</div>
</div>
<!-- Footer -->
<div class="footer footer--dark">
	<div class="container">
		<div class="footer__inner">
			<div class="footer__data">
				<div class="footer__data__item">
					<div class="footer__row">
						Version {import.meta.env.VITE_APPVERSION}
					</div>
					<div class="footer__row">
						Created by <a
							href="https://jedfonner.com"
							target="_blank"
							rel="noreferrer"
							class="footer__link">Jed Fonner</a>
					</div>
					<div class="footer__row">
						Logo icon created by <a
							href="https://www.flaticon.com/free-icon/diet_2739053"
							title="diet icon"
							class="footer__link">Smashicons - Flaticon</a>
					</div>
				</div>

				<div class="footer__data__item">
					<!--<div class="footer__row">
						<a href="/support" class="footer__link">Support</a>
					</div>-->
					<div class="footer__row">
						<a href="/privacy" class="footer__link">Privacy Policy</a>
					</div>
					<div class="footer__row">
						<a href="/terms" class="footer__link">Terms of Service</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	/* Evie styles */
	h1 {
		font-size: 2em;
		margin: 0.67em 0;
	}

	/* Text-level semantics
   ========================================================================== */
	/**
 * Remove the gray background on active links in IE 10.
 */
	a {
		background-color: transparent;
	}

	/* Embedded content
   ========================================================================== */
	/**
 * Remove the border on images inside links in IE 10.
 */
	img {
		border-style: none;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	img,
	svg {
		max-width: 100%;
	}

	.container {
		width: 93%;
		max-width: 1080px;
		margin: 0 auto;
		position: relative;
	}

	p {
		margin-bottom: 1.3em;
	}

	h1,
	h2 {
		margin: 1.414em 0 0.5em;
		line-height: 1.35;
		color: #111;
		font-weight: 400;
	}

	h1 {
		margin-top: 0;
		font-size: 2.074em;
	}

	h2 {
		font-size: 1.728em;
	}

	h4 {
		font-size: 1.2em;
	}

	a {
		outline: 0;
		text-decoration: none;
		transition: 0.7s;
		color: inherit;
	}
	.footer__link:focus {
		border: 1px dashed grey;
	}
	.button {
		display: inline-block;
		padding: 6pt 20pt;
		line-height: 1.7;
		border: 1px solid #6c63ff;
		color: #6c63ff;
		font-weight: 400;
		transition: 0.7s;
		white-space: nowrap;
		cursor: pointer;
		background-color: transparent;
		border-radius: 30px;
		margin-bottom: 1.3em;
		transition: 0.7s;
		text-align: center;
		text-decoration: none;
	}

	.button__accent {
		background-color: #06d19c;
		border-color: #06d19c;
		color: #fff;
	}
	.button__secondary {
		background-color: #fff;
		border-color: #fff;
		color: #000;
	}
	@media (min-width: 992px) {
		.button__accent:hover {
			background-color: #05bd8d;
			border-color: #05bd8d;
			color: #fff;
		}
		.button__secondary:hover {
			background-color: #e2dfdf;
			border-color: #e2dfdf;
			color: #000;
		}
	}

	.button:disabled {
		opacity: 0.25;
		cursor: default;
		pointer-events: none;
	}

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		font-size: 0.9em;
		opacity: 0.65;
		color: #666;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		font-size: 0.9em;
		opacity: 0.65;
		color: #666;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		font-size: 0.9em;
		opacity: 0.65;
		color: #666;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 + lt if placeholders.js */
		font-size: 0.9em;
		opacity: 0.65;
		color: #666;
	}

	.landing__section {
		padding: 24pt 0;
	}

	.landing__section:nth-child(odd) {
		background-color: #f7f7f7;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
	}

	.hero {
		position: relative;
		width: 100%;
		background-image: url('/images/hero_bg.jpeg');
		box-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		color: #ffffff;
	}
	.hero__inner {
		position: relative;
		z-index: 3;
	}

	.hero__overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0.9;
		z-index: 1;
		background-color: var(--theme-primary);
	}

	.hero__overlay--gradient {
		background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
	}
	/*
	.hero__overlay--gradientVertical {
		background: linear-gradient(to bottom, var(--theme-primary), #635bff);
	} */

	.hero__mask {
		position: absolute;
		height: 100%;
		width: 100%;
		/* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080"><defs><linearGradient id="06714b97-aec8-4b7e-bf3d-1e20f95f612e" y1="809.5" x2="958" y2="809.5" gradientTransform="matrix(1, 0, 0, -1, 0, 1082)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="1" stop-color="#fff" stop-opacity="0.05"/></linearGradient></defs><title>hero</title><rect id="0567095b-2cf7-4407-b8ab-ec2abe52d830" data-name="&lt;Path&gt;" y="545" width="1920" height="535" fill="#fff" opacity="0.05" style="isolation:isolate"/><polygon id="82ca9a61-a0a1-49cc-9805-a3533c693bad" data-name="&lt;Path&gt;" points="958 545 0 545 0 0 770.96 0 958 545" fill="url(#06714b97-aec8-4b7e-bf3d-1e20f95f612e)"/></svg>');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		opacity: 0.66;
		z-index: 2; */
	}

	.hero__content {
		-js-display: flex;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 50px);
		padding: calc(92px) 0;
		overflow: auto;
	}
	.hero__content__inner {
		width: 100%;
		max-width: 600px;
		text-align: center;
	}

	/*--- CONTENT STYLING ---*/
	.hero__title {
		color: inherit;
		margin-bottom: 1.25em;
	}

	.hero__text {
		opacity: 0.85;
		line-height: 1.7;
		text-align: left;
		font-size: 1.1em;
	}

	.hero .button {
		margin: 12pt 8pt;
	}

	.hero__button {
		color: #ffffff;
		border-color: #ffffff;
	}
	@media (min-width: 992px) {
		.hero__button:focus {
			border-width: 2px;
		}
	}

	.hero__sub {
		-js-display: flex;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
	}

	.hero__sub__down {
		width: 1rem;
		height: 1rem;
		color: #666;
		transition: 0.7s;
		cursor: pointer;
	}
	@media (min-width: 992px) {
		.hero__sub__down:hover {
			color: #6c63ff;
		}
	}

	#scrollToNext {
		transition: 0.7s;
	}

	.steps {
		text-align: center;
		padding: 16pt 0;
	}

	@media (min-width: 992px) {
		.steps__inner {
			-js-display: flex;
			display: flex;
			justify-content: space-around;
		}
	}

	.step {
		padding: 16pt 0;
		text-align: center;
		width: 100%;
		border-top: 1px solid #eee;
		overflow: auto;
	}
	.step:first-child {
		border-top: none;
	}
	@media (min-width: 992px) {
		.step {
			border-top: none;
			width: auto;
		}
	}

	.step__media {
		display: flex;
		justify-content: center;
		margin: 16pt 0;
	}
	@media (min-width: 992px) {
		.step__media {
			margin: 32pt 0;
		}
	}

	.step__image {
		max-height: 100px;
		max-width: 222px;
		margin-top: 8pt;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
	}
	@media (min-width: 600px) {
		.step__image {
			max-width: none;
		}
	}

	.step__text {
		max-width: 36ch;
		text-align: left;
		margin-left: auto;
		margin-right: auto;
	}

	/*
  .expanded {
		padding: 72pt 0;
	}
	.expanded:nth-child(even) .expanded__media {
		order: 1;
	}
	@media (min-width: 992px) {
		.expanded:nth-child(even) .expanded__media {
			order: 1;
		}
	}
  */

	.expanded__inner {
		-js-display: flex;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}
	@media (min-width: 992px) {
		.expanded__inner {
			flex-direction: row;
		}
	}

	.expanded__media {
		order: 1;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	@media (min-width: 992px) {
		.expanded__media {
			width: 50%;
			flex-shrink: 0;
			order: unset;
		}
	}

	.expanded__content {
		order: 2;
	}
	@media (min-width: 992px) {
		.expanded__content {
			padding: 0 16pt;
			width: 50%;
			flex-shrink: 0;
			order: unset;
		}
	}

	/* Actual content */
	.expanded__image {
		width: 90%;
		max-width: 220px;
		padding: 16pt 0;
	}
	@media (min-width: 992px) {
		.expanded__image {
			max-width: 280px;
		}
	}

	.expanded__title {
		max-width: 550px;
	}

	.expanded__text {
		max-width: 60ch;
	}
	.clickable-image {
		cursor: pointer;
	}

	.cta {
		background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
		color: #fff;
		padding: 48pt 0;
		text-align: center;
	}

	.cta--reverse {
		background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
	}
	.cta__inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.footer {
		background-color: #ffffff;
		padding: 36pt 0;
	}

	.footer__inner {
		-js-display: flex;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	@media (min-width: 600px) {
		.footer__inner {
			flex-direction: row;
		}
	}

	.footer__data {
		-js-display: flex;
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
		padding-top: 0.25rem;
		flex-wrap: wrap;
		flex-direction: column;
		font-size: 0.9rem;
	}
	@media (min-width: 992px) {
		.footer__data {
			flex-wrap: nowrap;
			flex-direction: row;
		}
	}

	.footer__data__item {
		padding-bottom: 0.5rem;
		display: inline-block;
		margin-bottom: 8pt;
	}

	.footer__link {
		color: #6c63ff;
		border-bottom: 1px dotted #6c63ff;
		padding-bottom: 1pt;
		transition: 0.7s;
	}
	@media (min-width: 992px) {
		.footer__link:hover {
			color: #fafafa;
			border-color: #fafafa;
		}
	}

	.footer__row {
		margin-bottom: 8pt;
	}

	/*--- Modifiers---*/
	.footer--dark {
		background-color: #333c44;
	}

	.footer--dark .footer__inner {
		color: #d9d9d9;
	}
	.footer--dark .footer__link {
		color: #d9d9d9;
		border-color: #d9d9d9;
	}
	@media (min-width: 992px) {
		.footer--dark .footer__link:hover {
			color: #fff;
			border-color: #fff;
		}
	}
</style>
